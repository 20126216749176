import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
display:grid;
justify-items: center;
align-items: center;
`; // add your styles here

const CounterText = styled.span`
display:grid;
place-items: center;
`; // add your styles here

const MintContainer = styled.div`
display:grid;
place-items: center;
`; // add your styles here

const MintButton = styled(Button)`
display:grid;
place-items: center;
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const whitelist = ["8roV4vocA5ZCPdaE17zqbkmbXDYBGsEQwNPHsVvLVC1e",
"2GkXD9d1J1K7R5ihDo6jPH4ceQZmi3v197YAKGGH37LZ",
"3q9Ycj5xXaW9XyjyLLNMjKt26iUJAp7QyXi2GFvZw6RK",
"HQqVSz5Yknc13SnJXBe7sZ5cRDkPrSnA1bgVw4uSkSiK",
"B41i5Nx5ynnmo1XfuUpDduetKzCXmbZAsmVJ4RpgBjCX",
"CmWedGqzAwmVz5eMRJyK5ioL7bqKHNZeKgMtAvHuHsHG",
"4dYe8NDUKCJir7hYoFSC2FCJwVa6uycEfyQZ7aYrCqih",
"J4bpQjmh7yBDVirALzVGGaqWjRiHuUzxwbwfCpuAgUT4",
"ANRWGzscXM36UDXrw3xTKaAEx2oc2zeS1b6ZSYqjH2ar",
"DUoDJZpgbEzmkrjAEWHt9SM82XLGXM3pdV6qj3sg8G3g",
"Cz3ECjGg18DJs3Pnj45pRBryj8LXsCbjwQWREdgUvn2Q",
"2YLpzgNRHyKwhv6XNgQ962C3Xtc218gBNuGEVK71eDd8",
"B6kdRxX4aQ1WbDqwWhuM2SnmfDayhG4eBc6rhsTYoV1t",
"6f29k5dUpaB1W9qtkZLYirh1Qqo16vHtoeBwjSC3xYay",
"EHFcWBEc3bNMxsN8fVaEWghEapVnJbdZ2U5s21n2STR7",
"HkjhoGw7iqQbmhYiDr1mjz2YVqMQgtcKLCFSA2NLwEnV",
"FRaAe1ut6QHg5hNaHrdsWrsvesytnqnpCvkcUGmpRm2w",
"HA6K3BTUXbv2Jd73Vi3xKn9vNPLK7EoVU5K3qgjiypL7",
"8EfEbdTHjcE86hEXgCgmmZjp61ev8qZ73VS1Sn7ZTNnt",
"Atf2CeHXjq7so3AouL9VvmEKhTw2cozMHThwhUNLby1R",
"FHrXai1eVm7GzRVUmVktTNffNf75GULx1nrvszYR7Ajj",
"GE4zBT41q1ukzF3jFUVvXq99jY3KfJp6ddshntU8PX4y",
"2BuZsT5pb5k5k8BHAbAUXyfqMQf7b6JMMtzg5Fobg48h",
"GfHCEQ6Hq3FJDdPTBaYc9eaHmnkhbqtBDiNWPueti7AD",
"yenprH6k14DH1YnmjUBGNzE2z2AKfJkd5pjwNhdxR5Z",
"C3QUVz3PpAPrkT5DYGaa7XmFETrw5hdaqcS9jxMjeyQt",
"FhMRwe6sGzj3tjKjWynTUfEeUFSEBniKzvuueLbUh5NZ",
"H894sB1QCHKtcQ8Uw8EJFVwLvQAmFu2uTfn8MwNhx6Yy",
"AspoGwLEGRpijLCHVsbKAN3AywM9CbWv84Asy4pYLjUq",
"34McnpCEmHmuq9JPv95ozFGcDRWR4UqtVk9ZYA35XT4e",
"DoeqXcbdADaoDVmC6Ekk2JR2982iMp6Um28WXzV3a7Lv",
"BKTfN6tAeR8eMz7kbK8FtZmbSL398ir5rjp4gtCQU74a",
"4mE1WEXtBpKNfLcTA7aXYBHspFbjFwHVEakkxUJ8Jhaa",
"HtHt6M2vEyt1sKVrzmrFJUGAjUCtgkYzVecgTYQhZBUJ",
"nNYkNe2kD12Ybk3kQJrM2vm9zFsRWPVtuMP6eKaDPrV",
"E4trx8jY97e5ZLGaDWYBr8aoLcCr6PLL3371iyAAA9tx",
"3ZMRiSvB4vsuCDLFMPbEE1ZphEQaxx4UEQ1E58j59PQ2",
"En6mpQJ1NvRgpVFCMNwVhat7TQxj5AVVT2cvsQSF3fNS",
"nnfWQk7Nz8z38HC3d5AhmJy9nPn5NaRsUCLDLHs4pQ8",
"8MYP1CLov3PStmuaTRLFnJ6HEszzM3qVjPXs5PjPiwVV",
"24h31NcFBpZTRYdBNqLp8Kj8DmnRCmzrEUntQvLULUVg",
"B6doUyFQafGhumFTfxZCTAS58irC9ywhYsG2aHKcLC5x",
"7PWtS3ARgiCmAVsBtzHQ4vuP9sXD631GDaob4V5iXxm5",
"1xmq2834S2N1sFiHTe59tHtQ8LTirqmY5LNrLrGtDC4",
"G9RCtkyncG42zEz3ZBp68crkhoJHKR3odeMvTntU3pP1",
"68FQEcG7uPXckigCMr7sETAXjuX5SQ43pZf326Jogckf",
"HFtrg3vQfiH7kkfKSUWBBHY9EZgQpYmNVLPk2c7sGP1e",
"9s17zZyyde3Kar29LeygPzaNTVBLz7QjeB4W5YgyYxtz",
"F2NP7VVXVmJznaaa6oEcBm6XAMa6Ag1kyj4uQcpvM7Ha",
"BTotRxcyTZZEinYseuoT6TXpzbTRkaJHpX88eWWjKpfL",
"7QC7aNpTSG6vC4SmXnJo27HFKdYbbmLJBhY4ykbdmRrp",
"4Keec2VTkkLsfdSHDwwAaNFPths64Dt7sskSbWcSA3i6",
"5CRtLcv4rpBEnAcT2PhnBESfwJc5aW1qspYwphi2qwEL",
"CLrBjCrPA11erVhGwNgVg8JHWcHeShcAuGQufMQab5EU",
"DeEy62C5o8cHn1B9zx4yNBrvFFGZUw6U2ij4snMfGYtY",
"2nUqXfP6Vd56zGTH1QU1mVwRtr4Doi8yzzoYJ1wAMbo2",
"2LUjDC5H1Vr9nJzSj2AdMWiqHmCDLm1GzD4xjUBSQdnJ",
"Bp4gbJxinhhTDQM5bc9tZm6aKtYXGhghgEtkaoEDfFiL",
"8XCqaSamzFUpSHgR8zsUhjUi6cpmEDiK2GdfQSRzxfDV",
"4TruVLKv5mQDmGysJwbVUi4mDDw58qQjFvDXeTg97TX2",
"ECynJxZWrmSSgRKjQ5pRRzSBYB1iutGd3qXeeDoXwQzN",
"22fhxLMuvjwrmU1opZENM9AvE7qcefRENVp5d2SwLZzh",
"3zGgSLjeKsLcmnbk2PLUrEYNxoUg5aCj2gaupn36w4a3",
"C9TLP4mArigFiaZN8eUGH315aUcundCafWEedxJRvpN5",
"DpQMcGodtxaeNCEB7VPnHmfz7mRoPY535aF79USkegeo",
"GpbT8tdknG4ZU7ZF2qn3bJuvZSVQZCMmKE9kVfeUi5Ny",
"4YeFWk6fLk7wamDc6KgKG3sSfbn1r6ZeQh1qW9i435fU",
"Bsd8NhRG1ktSWRETjNBNCLEZpaX4hkSSc37R4u8BFDY",
"J8DieW3E7RSAAJUDinKxfogssx9Q55gHzk5aEnAmF91p",
"9DNpAAGjpAhYNoghkBr6A1vfXPNNwULsi2yeMLZNARAi",
"CdvUxfdk54cas31ux2wKeeo21uMwxZqcEv2m94M4wG7v",
"53z3ZuCHXpBcG1bwsDQUyABo6apdpAX3wxR9GgeFuu82",
"2MxfWTp6iXndkcJE4SoegqHrgnWbGNieTAAU9V2zywRK",
"5pnLxBLdRiMFkCRe4KE4zPVWkQRRx7Y2z2JLC5q4Scjo",
"Xjv4eqoEopC5omGQsfpVC7tNdj8qkVKGM9kBQegCjKm",
"6oKcNTAgC6N2MPTmTfh1nRdWz6Ujy4BGJFYbkSr7QhNt",
"JDHsQgtAvBdSt2vL84j1nja4sBVa2nLEmTMzeANXTQsK",
"FLujMKqu3d8k6mGnZ28aEbSZSjhsbmccS6iweBPBCNhp",
"3c586QToy7KwHL8SKpMrcGc2nSBcD6fS4VPWfRqssTSR",
"9dCLTAJkF1LiLAUfQD2WK8PWjkm9ZdPcVe6TijpAXrMT",
"2YLpzgNRHyKwhv6XNgQ962C3Xtc218gBNuGEVK71eDd8",
"DLjQuXL94M8WRXM1SdwpUiMSFkthbcku5Mp5RZ4xSdqw",
"FmXer8oCFVDfDd9skKijQ69BnvAzKNJCnvwa8dZQrE2i",
"8Wiou5wMEKohqq8duXgjSh9tErbp2pqxkvwiVoBFGZwM",
"Agk5CX5ZvkXHXCPnXUDQs64SLZyMbno1cgbRhQZstcAy",
"9jSybzhWLPpFrd7U6NUWK7TAeqiSj9yKbNCHtLuhgLv8",
"C2hCE4pzzt669qVHZBbFrna23tAVGHvcvXDsoyDSrApp",
"3wgBbdV4w7sAfX4vbeyqRrjdov5ujusjtVD2Jub2L3AN",
"D9b55TMPQunvLxwChitMR6wMNp5PqvrkM8AH5euYK2ym",
"8Vqxy1Bv6QBUDWCkiX9PpLGFPH6UbUrL17QmrnWUDFcY",
"2b9Yi4Wjp7VkPcKviZRVx9iUJph5KtJ9qx3aoL4PYYxz",
"7bxrtezZzfkG4eCdCHQCopG4ndDrhzENj6D94x6cEJYp",
"4TruVLKv5mQDmGysJwbVUi4mDDw58qQjFvDXeTg97TX2",
"CYwavYyL8Sm8iYy57TTyWbN1cfGfRHwocxwJcMV6sQdv",
"E3bymo9u8BEG31MzCCNKhcygFfSHwwousmeJnGS9FRdX",
"9mYqKi3x3iYZhDeGJXek1b6yJKfTHnAqCufzrzdonPF4",
"2Pgkme3kdxRD42kzWfBPpzqrevwA7UxpeXimnjwqiaeY",
"7UGjG9q8uWAzJNFaMtPXE7gRgfqj3hvoxNo932o6CPqM",
"EFpbGoyZoRSwapW5CTsL8Z68Wgsu2cShAc6tNJpw8rPW",
"9YhQ4VQYx1voZTLf39EmnYiJBch7EhKwsTk6sw7hht3G",
"BcH5uBXtTpZGM4a7zBAaV8fxQPdvy5eFD1Mv2Hqn7RmR",
"4vc8HN572Kyuk9WHLhH1jZJDEKA9ccdRLb3RFjfZP9Wx",
"8VDz3Gdar5NRUKzXm42X8rN6rqFFyM2BPYif3wNgDWBf",
"2Vnz5hT7MGMDQ7ghJBcD6b7YzaS81zqwzfAw2VdKWahX",
"AeCsnEmQmDQiPQdcXcvnsjmv5eBdeYPNzcbHhAr2wW1A",
"J3RypQhnW9A8wKmoz7ZAbJr6BV5zC4aK7T5Ub7DDYENM"
];


const isWhitelisted = (usersKey: string) : boolean => {
  for (const pubkey of whitelist) {
    if (pubkey === usersKey) {
      return true;
    }
  }

  return false;
};


const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
	const wallet_addr = (wallet.publicKey.toBase58() || "");
	if ((isWhitelisted(wallet_addr)) || (process.env.REACT_APP_IS_WHITELIST_ACTIVE! === "false")) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      } else{
                setAlertState({
            open: true,
            message: "You are not whitelisted. Pick the right wallet, refresh website and try again!",
            severity: "error",
          });}
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"xx-large",fontFamily:"Courier New"}} ><p>65 Million Years</p> </div>}
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>Total Supply 650</p> </div>}
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>+200 T-Rex, +200 Triceratops, +200 Argentinosaurus</p> </div>}
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>Minting will be RANDOM, you can GET ANY SPECIES each time you mint.</p> </div>}
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>Minting and holding ALL THREES SPECIES guarantees future AIRDROPS.</p> </div>}
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>PRESALE MINT: 0.5 SOL ----  PUBLIC MINT: 0.65 SOL</p> </div>}
          {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>.</p> </div>}
      {wallet && (
        <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}}> <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p> </div>
      )}

      {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}}> <p>Balance: {(balance || 0).toLocaleString()} SOL</p> </div>}

      {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}}> <p>Total Dinos: {itemsAvailable - 850 + itemsRedeemed - itemsRedeemed}</p> </div>} 

      {wallet && <div style={{display:"flex", justifyContent:"center",fontSize:"large",fontFamily:"Courier New"}} ><p>Remaining: {itemsRemaining - 850}</p> </div>}

      {<div> </div>}

      <MintContainer>
        {!wallet ? (
          <ConnectButton>Connect Wallet</ConnectButton>
        ) : (
          <MintButton
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "MINT"
              )
            ) : (
              <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
        )}
      </MintContainer>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
